import React from 'react';
import dropbox from '../../../contents/integrations/dropbox/icon.svg';
import drive from '../../../contents/integrations/google-drive/icon.svg';
import slack from '../../../contents/integrations/slack/icon.svg';
import Button from '../ui/Button';
import Card from '../ui/Card';
import styles from './FeaturesIntegrationsInsert.module.scss';

const FeaturesIntegrationsInsert: React.SFC = () => (
  <Card>
    <div className={styles.container}>
      <div className={styles.text}>
        Slab integrates with dozens of applications so you can continue to use
        your existing workflows.
      </div>
      <div className={styles.icons}>
        <img src={drive} alt="Google Drive" />
        <img src={slack} alt="Slack" />
        <img src={dropbox} alt="Dropbox" />
      </div>
      <div className={styles.button}>
        <Button to="/integrations/">View All Integrations</Button>
      </div>
    </div>
  </Card>
);

export default FeaturesIntegrationsInsert;
